import { ReactNode } from 'react'

const FooterPhrase = (props: { text: string | ReactNode, step: number }) => {
    return (
        <div className='flex flex-col justify-center items-center font-normal text-neutral'>
            <progress className='progress progress-secondary w-screen rounded-none h-1' value={props.step} max={11}></progress>
            <div className='text-center text-xs w-full py-6'>
                <p>{props.text}</p>
            </div>
        </div>
    )
}

export default FooterPhrase