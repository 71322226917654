import { createAsyncThunk } from '@reduxjs/toolkit';
import { connection } from 'util/axios';

export const featuredWorkouts = createAsyncThunk(
    'workouts/featured',
    async (_, { rejectWithValue }) => {
        try {
            const response = await connection.get(`/workouts/featured`);
            return response.data.results;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch failed');
        }
    }
);

export const getWorkoutById = createAsyncThunk(
    'workouts/id',
    async (props: { id: string }, { rejectWithValue }) => {
        try {
            const { id } = props
            const response = await connection.get(`/workouts/${id}`);
            return response.data.results;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch failed');
        }
    }
);

export const getWorkoutVideos = createAsyncThunk(
    'workouts/videos',
    async (props: { id: string }, { rejectWithValue }) => {
        try {
            const { id } = props
            const response = await connection.get(`/workouts/${id}/playlist`);
            return response.data.results;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch failed');
        }
    }
);