import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useReturnPath } from './layout';

interface IFormInput {
  name: string;
  username: string;
  email: string;
  password: string;
  repeatPassword: string;
}

const SignupPage = () => {
  const navigate = useNavigate();
  const { setPrevPath } = useReturnPath();
  useEffect(() => {
    setPrevPath("/auth");
  })

  const schema = Yup.object().shape({
    name: Yup.string().required("Full name is required"),
    username: Yup.string().required("Username is required"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /[!+@#$%^&*()\-_"=+{}; :,<.>]/,
        "Password must have a special character"
      )
      .min(8, ({ min }) => `Password must be at least ${min} characters`),
    repeatPassword: Yup.string()
      .required("Password is required")
      .matches(
        /[!+@#$%^&*()\-_"=+{}; :,<.>]/,
        "Password must have a special character"
      )
      .min(8, ({ min }) => `Password must be at least ${min} characters`),
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(schema) as any, // Type assertion to resolve the issue
  });

  const onSubmit = async (values: any) => {
    if (values.password !== values.repeatPassword) {
      setError('repeatPassword', { type: 'custom', message: 'Passwords do not match' });
      return
    }
    navigate("/signup/set-profile");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='w-full h-full flex flex-col items-center justify-center form-control'>
      <p className='label font-bold text-white'>Create New Account</p>
      <div className="flex flex-col w-10/12 space-y-2">
        <div className={"w-full flex flex-col items-center relative"}>
          <input
            type="text"
            placeholder="Name"
            className={`input input-bordered rounded-full focus:outline-secondary w-full ${errors.name ? "border-warning" : ""}`}
            {...register("name")}
          />
          {errors.name && (
            <div className="text-sm text-error absolute right-0 top-0 p-4 opacity-50 font-bold">
              {errors.name?.message}
            </div>
          )}
        </div>
        <div className={"w-full flex flex-col items-center relative"}>
          <input
            type="text"
            placeholder="Username"
            className={`input input-bordered rounded-full focus:outline-secondary w-full ${errors.username ? "border-warning" : ""}`}
            autoComplete='username'
            {...register("username")}
          />
          {errors.username && (
            <div className="text-sm text-error absolute right-0 top-0 p-4 opacity-50 font-bold">
              {errors.username?.message}
            </div>
          )}
        </div>
        <div className={"w-full flex flex-col items-center relative"}>
          <input
            type="text"
            placeholder="Email"
            className={`input input-bordered rounded-full focus:outline-secondary w-full ${errors.email ? "border-warning" : ""}`}
            {...register("email")}
          />
          {errors.email && (
            <div className="text-sm text-error absolute right-0 top-0 p-4 opacity-50 font-bold">
              {errors.email?.message}
            </div>
          )}
        </div>
        <div className={"w-full flex flex-col items-center relative"}>
          <input
            type="password"
            placeholder="Password"
            className={`input input-bordered rounded-full focus:outline-secondary w-full ${errors.password ? "border-warning" : ""}`}
            autoComplete='new-password'
            {...register("password")}
          />
          {errors.password && (
            <div className="text-sm text-error absolute right-0 top-0 p-4 opacity-50 font-bold">
              {errors.password?.message}
            </div>
          )}
        </div>
        <div className={"w-full flex flex-col items-center relative"}>
          <input
            type="password"
            placeholder="Repeat Password"
            className={`input input-bordered rounded-full focus:outline-secondary w-full ${errors.repeatPassword ? "border-warning" : ""}`}
            autoComplete='repeat-password'
            {...register("repeatPassword")}
          />
          {errors.repeatPassword && (
            <div className="text-sm text-error absolute right-0 top-0 p-4 opacity-50 font-bold">
              {errors.repeatPassword?.message}
            </div>
          )}
        </div>
      </div>
      <p className='text-sm p-2'>Already have an Account? <Link to={"/signin"} className='text-secondary underline'>Sign In</Link></p>
      <div className='flex justify-center w-1/2'>
        <button
          type="submit"
          className="btn hover:btn-secondary btn-outline rounded-full btn-wide"
        >
          Create
        </button>
      </div>
    </form>
  );
};

export default SignupPage;

