import "assets/css/global.scss";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { yupResolver } from '@hookform/resolvers/yup';
import ShowPassword from "assets/img/icons/show-password-icon.svg";
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { login } from '../../redux/actions/authActions';
import { AppDispatch, RootState } from '../../redux/store';
import { useReturnPath } from './layout';

interface IFormInput {
  email: string;
  password: string;
}

// Define Yup schema for validation
const schema = yup.object().shape({
  email: yup.string().required('Email/Username is required'),
  password: yup.string().required('Password is required'),
});

const SigninPage: React.FunctionComponent = (props) => {
  const authState = useSelector((state: RootState) => state.auth); 
  const dispatch = useDispatch<AppDispatch>();
  const [showPassword, setShowPassword] = React.useState(false);
  const {
    setError,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(schema) as any, // Type assertion to resolve the issue
  });

  const { setPrevPath } = useReturnPath();
  useEffect(() => {
    setPrevPath("/auth");
  })

  useEffect(() => {
    if (authState.error) {
      setError("email", { type: "server", message: authState.error });
    }
  }, [authState.error, setError]);

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    dispatch(login(data));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='w-full h-full flex flex-col items-center justify-evenly form-control'>
      <p className='label text-xl text-white font-semibold'>Sign In</p>
      <div className="flex flex-col w-10/12 space-y-2 justify-evenly">
        <div className='relative'>
          <input
            type="text"
            placeholder="Email / User Name"
            autoComplete='username'
            className={`input input-bordered rounded-full focus:outline-secondary w-full relative ${errors.email ? " border-warning" : ""}`}
            {...register("email")}
          />
          {errors.email && (
            <div className="text-sm text-error absolute right-0 top-0 p-4 opacity-50 font-bold">
              {errors.email?.message}
            </div>
          )}
        </div>
        <div className="relative">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            className={`input input-bordered rounded-full focus:outline-secondary w-full relative ${errors.password ? " border-warning" : ""}`}
            autoComplete='current-password'
            {...register("password")}
          />
          <button
            type="button"
            className="absolute right-2 top-1/2 transform -translate-y-1/2"
            onClick={() => setShowPassword(!showPassword)}
          >
            <img src={ShowPassword} className={`p-4 h-12 ${showPassword ? "opacity-100" : "opacity-50"}`} alt="Show Password" />
          </button>
          {errors.password && (
            <div className="text-sm text-error absolute right-0 top-0 p-4 opacity-50 font-bold">
              {errors.password?.message}
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col items-center justify-center">
        <Link
          to={"/auth/forgot-password"}
          className="text-secondary text-sm tracking-wide underline"
        >
          Forgot Password?
        </Link>
        <p className="text-sm tracking-wide">
          Don't have an account? <Link to={"/auth/signup"} className='underline text-secondary'>Register Now.</Link>
        </p>
      </div>
      <div className='w-full flex justify-center p-2'>
        <button
          type="submit"
          className={`btn hover:btn-secondary btn-outline rounded-full w-8/12 `}
          disabled={authState.loading}
        >
          {authState.loading ? "Signin in..." : "Sign In"}
        </button>
      </div>
    </form>
  );
};

export default SigninPage;
