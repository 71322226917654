import { yupResolver } from '@hookform/resolvers/yup';
import FooterPhrase from 'components/FooterPhrase';
import { useReturnPath } from 'pages/auth/layout';
import React, { useEffect } from "react";
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";

interface IFormInput {
    activity: string | null;
}

const SetActivityPage = () => {
    const navigate = useNavigate();
    const { setPrevPath } = useReturnPath();
    useEffect(() => {
        setPrevPath(null);
    })

    const schema = Yup.object().shape({
        activity: Yup.string().nullable(),
    })

    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormInput>({
        defaultValues: {
            activity: localStorage.getItem('activity'),
        },
        resolver: yupResolver(schema) as any, // Type assertion to resolve the issue
    });

    const onSubmit = async (value) => {
        localStorage.setItem('activity', value.activity);
        navigate("/signup/set-purposee");
    }

    return (
        <React.Fragment>
            <form className='flex flex-col justify-center items-center h-full w-full'>
                <p className='text-label text-2xl font-bold text-center text-white'>How active are you?</p>
                {errors.activity && <p className='text-error text-center'>{errors.activity.message}</p>}
                <div className='flex flex-col justify-around space-y-2 w-10/12'>
                    <label className={`btn hover:btn-secondary rounded-full ${watch('activity') && watch('activity').includes('I NEVER EXCERCISE') ? 'btn-secondary' : 'btn-outline'}`}>
                        <span className="">I NEVER EXCERCISE</span>
                        <input type="radio" checked={watch('activity') && watch('activity').includes('I NEVER EXCERCISE')} className="hidden" value={'I NEVER EXCERCISE'} {...register('activity')} />
                    </label>
                    <label className={`btn hover:btn-secondary rounded-full ${watch('activity') && watch('activity').includes('LIGHTLY ACTIVE') ? 'btn-secondary' : 'btn-outline'}`}>
                        <span className="">LIGHTLY ACTIVE</span>
                        <input type="radio" checked={watch('activity') && watch('activity').includes('LIGHTLY ACTIVE')} className="hidden" value={'LIGHTLY ACTIVE'} {...register('activity')} />
                    </label>
                    <label className={`btn hover:btn-secondary rounded-full ${watch('activity') && watch('activity').includes('MODERATELY ACTIVE') ? 'btn-secondary' : 'btn-outline'}`}>
                        <span className="">MODERATELY ACTIVE</span>
                        <input type="radio" checked={watch('activity') && watch('activity').includes('MODERATELY ACTIVE')} className="hidden" value={'MODERATELY ACTIVE'} {...register('activity')} />
                    </label>
                    <label className={`btn hover:btn-secondary rounded-full ${watch('activity') && watch('activity').includes('EVERY DAY') ? 'btn-secondary' : 'btn-outline'}`}>
                        <span className="">EVERY DAY</span>
                        <input type="radio" checked={watch('activity') && watch('activity').includes('EVERY DAY')} className="hidden" value={'EVERY DAY'} {...register('activity')} />
                    </label>
                    <div className='flex justify-between'>
                        <button
                            onClick={() => navigate("/signup/set-preference")}
                            className="btn hover:btn-secondary btn-outline rounded-full px-8"
                        >
                            {"< Back"}
                        </button>
                        <button
                            type="submit"
                            className="btn hover:btn-secondary btn-outline rounded-full px-8"
                            onClick={handleSubmit(onSubmit)}
                        >
                            {"Next >"}
                        </button>
                    </div>
                </div>
                <Link to="/" className="underline text-center text-sm font-normal text-gray-600">skip</Link>
            </form>
            <FooterPhrase text={"We use this information to build better routines for you"} step={4} />
        </React.Fragment>
    );
};

export default SetActivityPage;
