import { useLocation, useNavigate } from 'react-router-dom';

const BottomNav = () => {
    const navigate = useNavigate()
    const location = useLocation()

    return (
        <div className="btm-nav z-30 lg:hidden">
            <button onClick={() => navigate('/')} className={location.pathname === '/' ? 'active text-secondary' : ''}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.5402 6.82006L14.7802 2.79006C13.2102 1.69006 10.8002 1.75006 9.29023 2.92006L4.28023 6.83006C3.28023 7.61006 2.49023 9.21006 2.49023 10.4701V17.3701C2.49023 19.9201 4.56023 22.0001 7.11023 22.0001H17.8902C20.4402 22.0001 22.5102 19.9301 22.5102 17.3801V10.6001C22.5102 9.25006 21.6402 7.59006 20.5402 6.82006ZM13.2502 18.0001C13.2502 18.4101 12.9102 18.7501 12.5002 18.7501C12.0902 18.7501 11.7502 18.4101 11.7502 18.0001V15.0001C11.7502 14.5901 12.0902 14.2501 12.5002 14.2501C12.9102 14.2501 13.2502 14.5901 13.2502 15.0001V18.0001Z" fill="currentColor" />
                </svg>
            </button>
            <button onClick={() => navigate('/library')} className={location.pathname === '/library' ? 'active text-secondary' : ''}>
                <svg width="25" height="25" viewBox="0 0 25 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M18.1322 21.5038H6.36733C5.13392 21.5038 4.10117 20.5692 3.97834 19.3419L3.25804 12.1389C3.19048 11.4639 3.41162 10.7918 3.86679 10.2888C4.32195 9.78579 4.96866 9.49878 5.64703 9.49878H18.8525C19.5309 9.49878 20.1776 9.78579 20.6328 10.2888C21.0879 10.7918 21.3091 11.4639 21.2415 12.1389L20.5212 19.3419C20.3984 20.5692 19.3657 21.5038 18.1322 21.5038Z" fill="currentColor" />
                    <path d="M6.24707 6.49752H18.2521" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.24805 3.4963H16.2514" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11.417 13.5H13.417" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </button>
            <button onClick={() => navigate('/workout')} className={location.pathname === '/workout' ? 'active text-secondary' : ''}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M13.957 7.90503L16.5942 10.5422L11.2587 15.8778L8.62149 13.2406L13.957 7.90503Z" fill="currentColor" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M3.92789 11.3403C4.29186 10.9763 4.88197 10.9763 5.24594 11.3403L13.1588 19.2531C13.5227 19.6171 13.5227 20.2072 13.1588 20.5712L12.4989 21.231C12.135 21.595 11.5448 21.595 11.1809 21.231L3.26804 13.3182C2.90407 12.9542 2.90407 12.3641 3.26804 12.0001L3.92789 11.3403Z" fill="currentColor" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M4.73453 14.4918C5.0985 14.1278 5.68861 14.1278 6.05258 14.4918L10.0096 18.4488C10.3736 18.8128 10.3736 19.4029 10.0096 19.7669L8.54408 21.2324C8.18011 21.5964 7.59 21.5964 7.22603 21.2324L3.26899 17.2754C2.90502 16.9114 2.90502 16.3213 3.26899 15.9573L4.73453 14.4918Z" fill="currentColor" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M21.3895 12.5495C21.0255 12.9134 20.4354 12.9134 20.0714 12.5495L12.1586 4.63664C11.7946 4.27267 11.7946 3.68256 12.1586 3.31859L12.8185 2.65874C13.1824 2.29478 13.7725 2.29478 14.1365 2.65874L22.0493 10.5716C22.4133 10.9355 22.4133 11.5257 22.0493 11.8896L21.3895 12.5495Z" fill="currentColor" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M20.3065 9.46781C19.9425 9.83178 19.3524 9.83178 18.9884 9.46781L15.0314 5.51076C14.6674 5.14679 14.6674 4.55668 15.0314 4.19272L16.456 2.76814C16.8199 2.40418 17.41 2.40417 17.774 2.76814L21.7311 6.72519C22.095 7.08916 22.095 7.67927 21.7311 8.04324L20.3065 9.46781Z" fill="currentColor" />
                </svg>
            </button>
            <button onClick={() => navigate('/profile')} className={location.pathname === '/profile' ? 'active text-secondary' : ''}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M12.4996 12C10.0326 12 8.0166 9.985 8.0166 7.517C8.0166 5.049 10.0326 3 12.4996 3C14.9666 3 16.9826 5.015 16.9826 7.483C16.9826 9.951 14.9666 12 12.4996 12Z" fill="currentColor" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M19.5 21H5.5C4.95 21 4.5 20.55 4.5 20V19C4.5 16.8 6.3 15 8.5 15H16.5C18.7 15 20.5 16.8 20.5 19V20C20.5 20.55 20.05 21 19.5 21Z" fill="currentColor" />
                </svg>
            </button>
        </div>
    )
}

export default BottomNav;