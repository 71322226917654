
export type ProgramCardProps = {
    id: string
    title: string
    image: string
    description: { difficulty: number, duration: number, day: string }
}
export type ProgramsCarouselProps = {
    loading: boolean
    title: string
    elements: ProgramCardProps[]
    handleSelectElement: (element) => void
}

const ProgramsCarousel = (props: ProgramsCarouselProps) => {
    const { title, elements, loading, handleSelectElement } = props
    return (
        <div className={`w-full h-2/6 lg:h-1/2 overflow-y-clip`}>
            {loading ?
                <div className="flex pl-2 w-full flex-col h-full">
                    <div className='my-2 w-5/6 pl-4 flex items-center'>
                        <div className="skeleton h-6 w-2/3 px-2 bg-secondary/10"></div>
                    </div>
                    <div className='flex h-full w-full space-x-4 pl-4'>
                        <div className={`skeleton bg-secondary/10 w-full`}></div>
                    </div>
                </div>
                :
                <div className="flex w-full flex-col h-full justify-center items-center">
                    <div className='w-full pl-4'>
                        <h1 className='text-xl text-white tracking-wider px-2 py-1'>{title}</h1>
                    </div>
                    <div className="carousel w-full h-full">
                        {elements.map((element, index) => (
                            <div onClick={() => handleSelectElement(element)} id={index.toString()} key={index} className={`carousel-item pl-4 h-full flex justify-center rounded-lg w-full lg:w-1/2 cursor-pointer`}>
                                <div className="relative card h-full shadow-xl w-full" style={{ backgroundImage: `url(${element.image})`, backgroundSize: 'cover', backgroundPosition: 'center', }}>
                                    <div className="card-body p-4 items-start justify-center z-10">
                                        <h2 className=" text-white text-2xl">{element.title}</h2>
                                        <div className="card-actions flex-col items-start">
                                            <div className="bg-black/50 border border-white rounded-full flex items-center">
                                                <div className='px-2 flex items-center'>
                                                    <svg className="mr-1" width="18" height="18" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clipPath="url(#clip0_7_13950)">
                                                            <path d="M9.53313 6.41949C9.53313 8.40688 7.95784 10.018 6.01461 10.018C4.07139 10.018 2.49609 8.40688 2.49609 6.41949C2.49609 5.6946 2.71223 4.48083 3.37572 3.86057L4.28186 5.06876C4.41037 5.2401 4.69772 5.14586 4.70794 4.93192C4.75959 3.84963 4.9941 1.81159 6.01461 1.22168C6.01461 1.22168 9.53313 4.4321 9.53313 6.41949Z" stroke="#FF4646" strokeWidth="0.659722" strokeLinejoin="round" />
                                                            <path d="M6.0145 8.6981C5.72572 8.6981 5.43976 8.64122 5.17295 8.5307C4.90615 8.42019 4.66373 8.25821 4.45952 8.054C4.25532 7.8498 4.09334 7.60738 3.98282 7.34057C3.87231 7.07377 3.81543 6.78781 3.81543 6.49902" stroke="#FF4646" strokeWidth="0.659722" strokeLinecap="round" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_7_13950">
                                                                <rect width="11" height="11" fill="white" transform="translate(0.736328 0.342041)" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                    <p>
                                                        Difficulty: {element.description.difficulty}
                                                    </p>
                                                </div>
                                                <div className='px-2 flex items-center'>
                                                    <svg className="mr-1" width="14" height="14" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="4.15723" cy="6.02631" r="3.5" stroke="white" strokeWidth="0.659722" />
                                                        <path d="M2.61523 0.845642H5.25412" stroke="white" strokeWidth="0.659722" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M3.93457 3.48456V5.66771C3.93457 5.678 3.93818 5.68796 3.94476 5.69586L5.03411 7.00308" stroke="white" strokeWidth="0.659722" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg><p>{element.description.duration % 60} min</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            }
        </div >
    )
}

export default ProgramsCarousel