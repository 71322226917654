import AppleLogo from "assets/img/icons/apple-logo.png";
import GoogleLogo from "assets/img/icons/google-logo.png";
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useReturnPath } from './layout';

const Login = () => {
    const { setPrevPath } = useReturnPath();
    useEffect(() => {
        setPrevPath(null);
    })

    return (
        <div className='flex flex-col justify-center items-center w-full'>
            <div className='flex flex-col justify-around w-10/12 space-y-2'>
                <p className='text-center font-black tracking-wide'>Designed to keep you consistent</p>
                <Link to={'/auth/signin'} className='btn hover:btn-secondary btn-outline rounded-full '>Sign In</Link>
                <Link to={'/auth/signup'} className='btn hover:btn-secondary btn-outline rounded-full'>Create Account</Link>
            </div>
            <div className='flex w-full h-full justify-center'>
                <div className='divider divider-secondary opacity-50 rounded-full w-10/12'></div>
            </div>
            <div className='flex flex-col justify-around w-10/12 space-y-2'>
                <button className='btn hover:btn-secondary btn-outline rounded-full'><img className='w-4 mx-2' src={AppleLogo} alt="apple-logo" />Continue With Apple</button>
                <button className='btn hover:btn-secondary btn-outline rounded-full'><img className='w-4 mx-2' src={GoogleLogo} alt="google-logo" />Continue With Google</button>
            </div>
        </div>
    )
};

export default Login;
