import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AppDispatch, RootState } from 'redux/store';
import BackIcon from '../../assets/img/icons/back-icon.png';
import { getAllPrograms, getProgramExercises } from '../../redux/actions/programActions';
import { selectProgram } from '../../redux/programSlice';

const ProgramPage = () => {
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const { id } = useParams()
    const { loading, loadingVideos, programs, current } = useSelector((state: RootState) => state.programs);

    const handleBack = () => {
        navigate('/');
    }

    useEffect(() => {
        if (programs.length === 0) {
            dispatch(getAllPrograms())
        } else {
            if (!current || current.id.toString() !== id) {
                dispatch(selectProgram({ program: programs.find(program => program.id === parseInt(id)) }))
                dispatch(getProgramExercises({ id }))
            }
        }
    }, [current, dispatch, id, loading, programs])

    return (
        <div className="h-full w-full font-['Poppins-Med'] pb-24">
            <div className='w-full p-4 flex relative h-0.2'>
                <button onClick={handleBack} className='absolute top-4 font-black text-white p-2'><img src={BackIcon} alt="Back Icon" className='h-4' /></button>
                <div className='w-full flex justify-center items-center'><p className='w-fit rounded px-2 h-fit text-white text-xl'>Program</p></div>
            </div>
            <div className={`h-2/6`} style={{ backgroundImage: "url(" + current?.cover_image + ")", backgroundPosition: 'center 25%', backgroundSize: 'cover' }}>
                <div className='relative flex flex-col h-full justify-end items-center p-4'>
                    <div className='bg-gradient-to-t from-black to-transparent opacity-50 w-full h-full inset-0 absolute z-0' />
                    <p className='text-2xl font-bold text-center text-white uppercase z-10'>{current?.title}</p>
                    <button className='btn btn-circle btn-outline btn-wide bg-black/75 text-white tracking-wider font-light z-10'>Start Now</button>
                </div>
            </div>
            <div className='w-full flex justify-evenly h-1/6 p-2'>
                <div className='flex items-center justify-center'>
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M21 17C20.7348 17 20.4804 17.1054 20.2929 17.2929C20.1054 17.4804 20 17.7348 20 18V19H19C18.4696 19 17.9609 19.2107 17.5858 19.5858C17.2107 19.9609 17 20.4696 17 21V31C17 31.5304 17.2107 32.0391 17.5858 32.4142C17.9609 32.7893 18.4696 33 19 33H31C31.5304 33 32.0391 32.7893 32.4142 32.4142C32.7893 32.0391 33 31.5304 33 31V21C33 20.4696 32.7893 19.9609 32.4142 19.5858C32.0391 19.2107 31.5304 19 31 19H30V18C30 17.7348 29.8946 17.4804 29.7071 17.2929C29.5196 17.1054 29.2652 17 29 17C28.7348 17 28.4804 17.1054 28.2929 17.2929C28.1054 17.4804 28 17.7348 28 18V19H22V18C22 17.7348 21.8946 17.4804 21.7071 17.2929C21.5196 17.1054 21.2652 17 21 17ZM21 22C20.7348 22 20.4804 22.1054 20.2929 22.2929C20.1054 22.4804 20 22.7348 20 23C20 23.2652 20.1054 23.5196 20.2929 23.7071C20.4804 23.8946 20.7348 24 21 24H29C29.2652 24 29.5196 23.8946 29.7071 23.7071C29.8946 23.5196 30 23.2652 30 23C30 22.7348 29.8946 22.4804 29.7071 22.2929C29.5196 22.1054 29.2652 22 29 22H21Z" fill="#C2C2C2" />
                        <circle cx="25" cy="25" r="24" stroke="#C2C2C2" strokeWidth="2" />
                    </svg>
                    <div className='flex flex-col items-center p-2'>
                        <p>Duration</p>
                        <p>{current?.program_duration} Days</p>
                    </div>
                </div>
                <div className='divider divider-horizontal' />
                <div className='flex items-center justify-center'>
                    <p className='p-4 text-xl'>{(Math.floor(Number.parseInt(current?.status) / current?.program_duration * 100))}%</p>
                    <div className='flex flex-col items-center p-2'>
                        <p>Completed</p>
                        <p>{current?.status}/{current?.program_duration} DAYS</p>
                    </div>
                </div>
            </div>
            <div className='w-full p-2 h-1/6'>
                <div className="px-[1rem] text-lg font-medium truncate">Description:</div>
                <div className='text-sm font-extralight truncate px-4'>
                    {
                        loading ?
                            <p className='skeleton bg-secondary/10 h-full'></p>
                        :
                            current?.description

                    }
                </div>
            </div>
            <div className='w-full h-1/4'>
                <p className='px-[1rem] font-medium text-lg'>Exercises:</p>
                <div className='carousel w-full h-full'>
                    {loadingVideos ? <div className='h-full w-full pl-4 flex'><div className='skeleton bg-secondary/10 w-2/3 h-full'></div><div className='skeleton ml-4 bg-secondary/10 w-2/3 h-full'></div></div> :
                        current?.exercises?.map((exercise, index) =>
                            <div className='carousel-item rounded-full'>
                                <div key={index} className='card relative p-4 h-full'>
                                    <img className='w-full h-full rounded' src={exercise?.workout_cover_image} alt='workout' />
                                    <div className='card-body p-2 absolute text-white bottom-0'>
                                        <p className='text-sm'>{exercise.workout_title}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
            </div>
        </div>
    )
}

export default ProgramPage