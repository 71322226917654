import FooterPhrase from 'components/FooterPhrase';
import { useReturnPath } from 'pages/auth/layout';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

const ChoosePlanPage = () => {
    const navigate = useNavigate();
    const [plan, setPlan] = useState<number>(0);
    const { setPrevPath } = useReturnPath()
    useEffect(() => {
        setPrevPath("/signup/welcome");
    })

    const {
        watch,
        handleSubmit
    } = useForm({
        defaultValues: {
            plan: parseInt(localStorage.getItem("plan"))
        }
    })

    useEffect(() => {
        setPlan(watch("plan"));
    }, [watch])

    const selectPlan = (value: number) => {
        if (plan === value) {
            setPlan(0);
        } else {
            setPlan(value);
        }
    }

    const onSubmit = () => {
        localStorage.setItem("plan", plan.toString());
        if (plan) {
            navigate("/signup/checkout");
        } else {
            navigate("/signup/set-profile-picture");
        }
    }

    return (
        <form className='h-full flex flex-col justify-evenly items-center w-full text-white font-semibold'>
            <span className='text-white font-bold text-2xl'>Welcome</span>
            <div className='flex flex-col justify-center items-center font-normal tracking-tighter w-11/12'>
                <p className='text-center '>For A Limited Time, If You Purchase An Annual Pass, <span className='text-secondary'>You Will Be Able To Gift 1 Extra Workout</span> Annual Pass</p>
                <p className='text-center text-secondary font-bold tracking-wider'>AVERAGE COST OF A PERSONAL TRAINER IS <span className='text-lg'>$1,000</span> PER MONTH</p>
            </div>
            <div className='w-full flex flex-col items-center p-2'>
                <div onClick={() => selectPlan(1)} className={`card bg-base-100 ${plan === 1 ? "border-secondary hover:border-white" : "border-white hover:border-secondary"} border focus:border-secondary w-10/12 cursor-pointer hover:scale-105`}>
                    <div className="flex-row card-body font-normal p-2">
                        <div className='h-full flex items-center'>
                            <input type='checkbox' checked={plan === 1} className='rounded-full' />
                        </div>
                        <div className='flex flex-row justify-between w-full pl-2'>
                            <div className='flex flex-col h-full'>
                                <h2 className="font-light"><span className='font-bold'>1 Year</span> Commit for a year</h2>
                                <p className='text-gray-600 text-xs'>$228 paid yearly (50% OFF)</p>
                            </div>
                            <div className="flex flex-col h-full justify-center items-center">
                                <span className="text-secondary">$<span className='text-2xl'>19</span>/m</span>
                                <span className="text-xs text-gray-600">(62¢ per day)</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div onClick={() => selectPlan(2)} className={`card bg-base-100 ${plan === 2 ? "border-secondary hover:border-white" : "border-white hover:border-secondary"} border focus:border-secondary w-10/12 cursor-pointer hover:scale-105`}>
                    <div className="flex-row card-body font-normal p-2">
                        <div className='h-full flex items-center'>
                            <input type='checkbox' checked={plan === 2} className='rounded-full' />
                        </div>
                        <div className='flex flex-row justify-between w-full pl-2'>
                            <div className='flex flex-col h-full'>
                                <h2 className="font-light"><span className='font-bold'>1 Month</span></h2>
                                <p className='text-gray-600 text-sm'>$39 paid monthly</p>
                            </div>
                            <div className="flex flex-col h-full justify-center items-center">
                                <span className="text-secondary">$<span className='text-2xl'>39</span>/m</span>
                                <span className="text-xs text-gray-600"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button onClick={handleSubmit(onSubmit)} type='submit' className='btn btn-outline btn-wide rounded-full'>{plan > 0 ? "Continue" : "Start Your Free Trial"}</button>
            <Link to="/" className="underline text-center text-sm font-normal text-gray-600">skip</Link>
            <FooterPhrase text={"Get Ready For Progress"} step={9} />
        </form>
    )
}

export default ChoosePlanPage