import Hero from 'components/Hero';
import { useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

type ContextType = { prevPath: string | null, setPrevPath: (prevPath: string | null) => void };

const SignupLayout = () => {
    const [prevPath, setPrevPath] = useState<string | null>(null);
    return (
        <div className='w-screen h-screen bg-smoke-blue bg-cover bg-bottom flex flex-col items-center justify-evenly'>
            <div className='h-full w-full md:w-10/12 lg:w-7/12 xl:w-5/12 flex flex-col node-smoke-blue font-["SF-Pro-Display"] tracking-wider'>
                <div className='h-1/3 flex items-center'>
                    <Hero prevPath={prevPath} />
                </div>
                <div className='w-full h-2/3 flex flex-col'>
                    <Outlet context={{ prevPath, setPrevPath } satisfies ContextType} />
                </div>
            </div>
        </div>
    )
}

export function useReturnPath() {
    return useOutletContext<ContextType>();
}

export default SignupLayout;