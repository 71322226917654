import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAllTrainers } from './actions/trainerActions';

export interface Trainer {
    id: number,
    title: string,
    username: string,
    slug: string,
    description: string,
    status: number,
    created_at: string,
    updated_at: string,
    cover_image: string
    featured: boolean
}

export interface TrainerState {
    trainers: Trainer[]
    loading: boolean
    error: string
}

const initialState: TrainerState = {
    trainers: [],
    loading: false,
    error: null
};

const trainerSlice = createSlice({
    name: 'trainers',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllTrainers.pending, (state) => {
                state.loading = true;
                state.error = null
            })
            .addCase(getAllTrainers.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false
                state.trainers = action.payload.trainers
                state.error = null
            })
            .addCase(getAllTrainers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    }
});



export const { } = trainerSlice.actions;

export default trainerSlice.reducer;
