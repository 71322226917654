import { createAsyncThunk } from '@reduxjs/toolkit';
import { connection } from 'util/axios';

export const getAllTrainers = createAsyncThunk(
    'trainers/all',
    async (props, { rejectWithValue }) => {
        try {
            const response = await connection.get(`/trainers?skip=1&take=10`);
            return response.data.results;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch');
        }
    }
);