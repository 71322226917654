import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { featuredWorkouts, getWorkoutById, getWorkoutVideos } from './actions/workoutActions';

export interface Workout {
    id: string,
    title: string,
    workout_duration: number,
    description: string,
    created_at: string,
    updated_at: string,
    order?: number,
    featured: boolean,
    published: boolean,
    published_at?: string,
    workout_rest_video_id?: string,
    workout_playlist_item_id?: string,
    trainer_id: number,
    difficulty: number,
    user_id: string,
    mode: number,
    category_id: string,
    is_daily: boolean,
    trainer_title: string,
    trainer_username: string,
    is_favorite: number,
    workout_cover_image: string
    workout_cover_image_mobile: string
    videos?: WorkoutVideo[]
    currentVideo?: WorkoutVideo
}

export interface WorkoutVideo {
    id: string
    title: string
    video_thumbnail_image: string
    workout_stream_url: string
    trainer_name: string
}

export interface WorkoutState {
    workouts: Workout[]
    current?: Workout
    loading: boolean
    loadingVideos: boolean
    error: string
}

const initialState: WorkoutState = {
    workouts: [],
    loading: false,
    loadingVideos: false,
    error: null
};

const workoutSlice = createSlice({
    name: 'workouts',
    initialState,
    reducers: {
        selectWorkout(state, action: PayloadAction<any>) {
            state.current = action.payload
        },
        selectVideo(state, action: PayloadAction<any>) {
            state.current.currentVideo = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(featuredWorkouts.pending, (state) => {
                state.loading = true;
                state.error = null
            })
            .addCase(featuredWorkouts.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false
                state.workouts = action.payload
                state.error = null
            })
            .addCase(featuredWorkouts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(getWorkoutById.pending, (state) => {
                state.loading = true;
                state.error = null
            })
            .addCase(getWorkoutById.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false
                state.current = action.payload[0]
                state.error = null
            })
            .addCase(getWorkoutById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(getWorkoutVideos.pending, (state) => {
                state.loadingVideos = true;
                state.error = null
            })
            .addCase(getWorkoutVideos.fulfilled, (state, action: PayloadAction<any>) => {
                state.loadingVideos = false
                state.current.videos = action.payload
                state.error = null
            })
            .addCase(getWorkoutVideos.rejected, (state, action) => {
                state.loadingVideos = false;
                state.error = action.payload as string;
            });
    }
});

export const { selectVideo, selectWorkout } = workoutSlice.actions;

export default workoutSlice.reducer;
