import ProfilePicture from 'assets/img/signup/profile-picture.png'
import FooterPhrase from 'components/FooterPhrase'
import { useReturnPath } from 'pages/auth/layout'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

const SetProfilePicturePage = () => {
    const { setPrevPath } = useReturnPath()
    useEffect(() => {
        setPrevPath("/signup/choose-plan");
    })
    return (
        <div className='h-full flex flex-col justify-evenly items-center w-full'>
            <span className='text-white font-bold text-2xl'>Profile Picture</span>
            <div className='flex flex-col justify-center items-center font-normal w-1/2 space-y-4'>
                <div className='flex items-center justify-start'>
                    <img src={ProfilePicture} alt='Profile' />
                </div>
                <input type="file" className="file-input file-input-bordered w-full max-w-xs" accept='image/*' />
            </div>
            <div className='flex flex-col items-center'>
                <p className='text-secondary'>Make Sure Your Picture Is:</p>
                <p><span className='text-secondary'>I.</span> less than 2MB</p>
                <p><span className='text-secondary'>II.</span> has dimensions smaller than 1200x1200px</p>
            </div>
            <Link to={"/home"} className='btn btn-outline btn-wide rounded-full'>Save & Continue</Link>
            <FooterPhrase text={"Get Ready For Progress"} step={11} />
        </div>
    )
}

export default SetProfilePicturePage