import { Middleware, PayloadAction } from '@reduxjs/toolkit';
import { logout, setTokens } from '../redux/authSlice';
import { connection } from '../util/axios';

const authMiddleware: Middleware = store => next => async (action: PayloadAction<any>) => {
    if (action.type === 'auth/refreshToken') {
        const refreshToken = store.getState().auth.refreshToken;
        try {
            const response = await connection.post('/token/refresh', { refreshToken });
            const { accessToken, refreshToken: newRefreshToken } = response.data;
            store.dispatch(setTokens({ accessToken, refreshToken: newRefreshToken }));
        } catch (error) {
            store.dispatch(logout());
        }
    }

    return next(action);
};

export default authMiddleware;
