import { createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { connection } from 'util/axios';

export const login = createAsyncThunk(
    'auth/login',
    async (props: { email: string; password: string }, { rejectWithValue }) => {
        try {
            const data = {
                email: props.email,
                password: props.password,
            };
            const response = await connection.post(`/auth/login`, data);
            Cookies.set("access_token", response.data.results.access_token);
            return response.data.results;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Login failed');
        }
    }
);

export const getCurrentUser = createAsyncThunk(
    'auth/current',
    async (_, { rejectWithValue }) => {
        try {
            const response = await connection.get(`/auth/current/user`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch failed');
        }
    }
);

export const refreshToken = createAsyncThunk(
    'auth/refresh',
    async (data, { rejectWithValue }) => {
        try {
            const body = {
                refresh_token: data
            }
            const response = await connection.post(`/auth/refresh-token`, body);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch failed');
        }
    }
);

