// @ts-ignore */
import { AuthProvider } from 'components/AuthProvider';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import { setupAxiosInterceptors } from 'util/axios';
import Application from './application';
import './assets/css/global.scss';
import { setTokens } from './redux/authSlice';
import { persistor, store } from './redux/store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const getAccessToken = () => store.getState().auth.access_token;
const getRefreshToken = () => store.getState().auth.refresh_token;

const onTokensRefreshed = (accessToken: string, refreshToken: string) => {
  store.dispatch(setTokens({ accessToken, refreshToken }));
};

setupAxiosInterceptors(getAccessToken, getRefreshToken, onTokensRefreshed);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AuthProvider>
        <Application />
      </AuthProvider>
    </PersistGate>
  </Provider>
);
