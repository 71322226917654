import IntroductionImage from 'assets/img/signup/introduction.png';
import FooterPhrase from 'components/FooterPhrase';
import { useReturnPath } from 'pages/auth/layout';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const IntroductionPage = () => {
    const { setPrevPath } = useReturnPath();
    useEffect(() => {
        setPrevPath("/signup/set-purposee");
    })
    return (
        <div className='h-full flex flex-col justify-evenly items-center w-full text-white font-semibold'>
            <span className='text-white font-bold text-2xl pb-12'>Introduction</span>
            <div className='flex flex-col justify-center items-center font-normal tracking-tighter'>
                <p><span className='text-secondary'>Our Workouts</span></p>
                <p>have an intensitiy rating from X1 to X7.</p>
                <p><span className='text-secondary'>X1</span> workouts are</p>
                <p><span className='text-secondary'>Quicker</span> and <span className='text-secondary'>Easier</span></p>
            </div>
            <img src={IntroductionImage} alt='Introduction' />
            <Link to={"/signup/weight-selection"} className='btn btn-outline btn-wide rounded-full'>Continue</Link>
            <Link to="/" className="underline text-center text-sm font-normal text-gray-600">skip</Link>
            <FooterPhrase text={"Get Ready For Progress"} step={6} />
        </div>
    )
}

export default IntroductionPage