import { yupResolver } from '@hookform/resolvers/yup';
import FooterPhrase from 'components/FooterPhrase';
import { useReturnPath } from 'pages/auth/layout';
import { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";

interface IFormInput {
    gender: string;
    day: number;
    month: number;
    year: number;
    ft: number;
    lbs: number;
}

const SetProfilePage = () => {
    const navigate = useNavigate();

    const { setPrevPath } = useReturnPath();
    useEffect(() => {
        setPrevPath(null);
    })

    const [selectedGender, setSelectedGender] = useState<string>("")

    const schema = Yup.object().shape({
        gender: Yup.string().required("Gender is required"),
        month: Yup.number().required("Month is required").min(1, "Invalid month").max(12, "Invalid month").typeError("Invalid month"),
        day: Yup.number().required("Month is required").min(1, "Invalid day").max(31, "Invalid day").typeError("Invalid day"),
        year: Yup.number().required("Month is required").min(1900, "Invalid year").max(new Date().getFullYear(), "Invalid year").typeError("Invalid year"),
        ft: Yup.number()
            .required("Height is required").typeError("Height is required"),
        lbs: Yup.number().required("Weight is required").typeError("Weight is required"),

    });

    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormInput>({
        defaultValues: {
            gender: localStorage.getItem('gender'),
            day: parseInt(localStorage.getItem('day')),
            month: parseInt(localStorage.getItem('month')),
            year: parseInt(localStorage.getItem('year')),
            ft: parseInt(localStorage.getItem('ft')),
            lbs: parseInt(localStorage.getItem('lbs')),
        },
        resolver: yupResolver(schema) as any, // Type assertion to resolve the issue
    });

    useEffect(() => {
        setSelectedGender(localStorage.getItem('gender'));
    }, [])

    useEffect(() => {
        setValue("gender", selectedGender);
    }, [selectedGender, setValue])

    const onSubmit = async (data) => {
        localStorage.setItem('gender', data.gender);
        localStorage.setItem('month', data.month.toString());
        localStorage.setItem('day', data.day.toString());
        localStorage.setItem('year', data.year.toString());
        localStorage.setItem('ft', data.ft.toString());
        localStorage.setItem('lbs', data.lbs.toString());
        navigate("/signup/set-goal");
    };

    return (
        <div className='h-full flex flex-col items-center justify-between'>
            <p className='text-center font-bold'>Let's personalize Your Profile</p>
            <div className='w-full flex flex-col items-center relative'>
                <div className='flex items-center justify-between w-10/12'>
                    <button onClick={() => setSelectedGender("male")} className={`btn w-5/12 rounded-full ${selectedGender === "male" ? "btn-secondary" : "btn-outline"}`} value={"male"} {...register("gender")}>Male</button>
                    <button onClick={() => setSelectedGender("female")} className={`btn w-5/12 rounded-full ${selectedGender === "female" ? "btn-secondary" : "btn-outline"}`} value={"female"} {...register("gender")}>Female</button>
                </div>
                {errors.gender && (
                    <div className="text-sm text-error absolute -top-8 opacity-50 font-bold">
                        {errors.gender?.message}
                    </div>
                )}
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col items-center space-y-2 w-10/12'>
                <div className='w-full flex justify-between items-center input input-bordered rounded-full focus:outline-secondary text-neutral relative'>
                    <input type='number' placeholder="Month" className='w-1/3 flex justify-between input text-neutral' {...register("month")} />
                    <input type='number' placeholder="Day" className='w-1/3 flex justify-between input text-neutral' {...register("day")} />
                    <input type='number' placeholder="Year" className='w-1/3 flex justify-between input text-neutral' {...register("year")} />

                    <div className="text-sm text-error absolute left-0 -top-10 p-4 opacity-50 font-bold">
                        {errors.month &&
                            errors.month?.message
                        }
                    </div>
                    <div className="text-sm text-error absolute left-1/3 -top-10 p-4 opacity-50 font-bold">
                        {errors.day &&
                            errors.day?.message
                        }
                    </div>
                    <div className="text-sm text-error absolute right-0 -top-10 p-4 opacity-50 font-bold">
                        {errors.year &&
                            errors.year?.message
                        }
                    </div>
                </div>
                <div className='relative w-full'>
                    <input type='number' name={"ft"} placeholder='Height' className='w-full flex justify-between input input-bordered rounded-full focus:outline-secondary text-neutral' {...register("ft")} />
                    <button
                        type="button"
                        className="absolute right-2 top-1/2 transform -translate-y-1/2"

                    >
                        <p className='p-4'>fts</p>
                    </button>
                    {errors.ft && (
                        <div className="text-sm text-error absolute left-1/3 top-0 p-4 opacity-50 font-bold">
                            {errors.ft?.message}
                        </div>
                    )}
                </div>
                <div className='relative w-full'>
                    <input type='number' name={"lbs"} placeholder='Weight' className='w-full flex justify-between input input-bordered rounded-full focus:outline-secondary text-neutral' {...register("lbs")} />
                    <button
                        type="button"
                        className="absolute right-2 top-1/2 transform -translate-y-1/2"
                    >
                        <p className='p-4'>lbs</p>
                    </button>
                    {errors.lbs && (
                        <div className="text-sm text-error absolute left-1/3 top-0 p-4 opacity-50 font-bold">
                            {errors.lbs?.message}
                        </div>
                    )}
                </div>
            </form>
            <div className='flex justify-between w-10/12'>
                <Link
                    to={"/auth/signup"}
                    className="btn hover:btn-secondary btn-outline rounded-full px-8"
                >
                    {"< Back"}
                </Link>
                <button
                    type="submit"
                    className="btn hover:btn-secondary btn-outline rounded-full px-8"
                    onClick={handleSubmit(onSubmit)}
                >
                    {"Next >"}
                </button>
            </div>
            <Link to="/" className="underline text-center text-sm font-normal text-gray-600">skip</Link>
            <FooterPhrase text="We use this information to biuld better routines for you" step={1} />
        </div>
    );
};

export default SetProfilePage;
