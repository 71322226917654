import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCurrentUser, login, refreshToken } from './actions/authActions';

export type User = {
  user_id: string,
  email: string,
  user_email: string,
  user_username: string,
  user_name: string,
  profile_image: string
}


export interface AuthState {
  access_token: string
  email_verified: boolean
  refresh_token: string
  current?: User
  loading: boolean
  error: string | null
}

const initialState: AuthState = {
  access_token: '',
  email_verified: false,
  refresh_token: '',
  loading: false,
  error: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setTokens(state, action: PayloadAction<any>) {
      state.access_token = action.payload.access_token
      state.refresh_token = action.payload.refresh_token
      state.email_verified = action.payload.email_verified
    },
    logout: (state) => {
      state.access_token = null;
      state.email_verified = null;
      state.refresh_token = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null
      })
      .addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.access_token = action.payload.access_token
        state.email_verified = action.payload.email_verified
        state.refresh_token = action.payload.refresh_token
        state.error = null
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(getCurrentUser.pending, (state) => {
        state.loading = true;
        state.error = null
      })
      .addCase(getCurrentUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.current = action.payload
        state.error = null
      })
      .addCase(getCurrentUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(refreshToken.pending, (state) => {
        state.loading = true;
        state.error = null
      })
      .addCase(refreshToken.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.current = action.payload
        state.error = null
      })
      .addCase(refreshToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  }
});

export const { setTokens, logout } = authSlice.actions;

export default authSlice.reducer;
