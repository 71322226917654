import { yupResolver } from '@hookform/resolvers/yup';
import CardIcon from 'assets/img/icons/card-icon.png';
import WarningIcon from 'assets/img/icons/warning-icon.png';
import FooterPhrase from 'components/FooterPhrase';
import { useReturnPath } from 'pages/auth/layout';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

interface IFormInput {
    name: string;
    cardNumber: string;
    expirationDate: string;
    cvv: string;
    coupon: string;
}

const CheckoutPage = () => {
    const navigate = useNavigate();

    const { setPrevPath } = useReturnPath()
    useEffect(() => {
        setPrevPath("/signup/choose-plan");
    })
    const schema = Yup.object().shape({
        name: Yup.string(),
        cardNumber: Yup.string(),
        expirationDate: Yup.string(),
        cvv: Yup.string(),
        coupon: Yup.string().nullable(),

    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormInput>({
        resolver: yupResolver(schema) as any, // Type assertion to resolve the issue
    });

    const onSubmit = async (values: any) => {
        navigate("/signup/set-profile-picture");
    };


    return (
        <div className='h-full flex flex-col justify-evenly'>
            <form onSubmit={handleSubmit(onSubmit)} className='w-full flex flex-col items-center justify-center form-control'>
                <p className='label text-xl font-bold text-white'>Payment Details</p>
                <div className="flex flex-col w-10/12 space-y-4">
                    <div className={"w-full flex flex-col items-center"}>
                        <input
                            type="text"
                            placeholder="Card Holder's Name"
                            className="input input-bordered rounded-full focus:outline-secondary w-full"
                            {...register("name")}
                        />
                        {errors.name && (
                            <div className="text-sm text-[#F42852]">
                                {errors.name?.message}
                            </div>
                        )}
                    </div>
                    <div className={"w-full flex flex-col items-center relative"}>
                        <input
                            type="text"
                            placeholder="Card Number"
                            className="input input-bordered rounded-full focus:outline-secondary w-full"
                            {...register("cardNumber")}
                        />
                        <div
                            className="absolute right-0 top-1/2 transform -translate-y-1/2"
                        >
                            <img src={CardIcon} className={`p-4 h-12`} alt='card' />
                        </div>
                        {errors.cardNumber && (
                            <div className="text-sm text-[#F42852]">
                                {errors.cardNumber?.message}
                            </div>
                        )}
                    </div>
                    <div className='w-full flex justify-between'>
                        <div className={"flex flex-col items-center"}>
                            <input
                                type="text"
                                placeholder="Expriation Date"
                                className="input input-bordered rounded-full focus:outline-secondary w-full"
                                {...register("expirationDate")}
                            />
                            {errors.expirationDate && (
                                <div className="text-sm text-[#F42852]">
                                    {errors.expirationDate?.message}
                                </div>
                            )}
                        </div>
                        <div className={"flex flex-col items-center w-1/3 relative"}>
                            <input
                                type="password"
                                placeholder="CVV"
                                className="input input-bordered rounded-full focus:outline-secondary w-full"
                                {...register("cvv")}
                            />
                            <div
                                className="absolute right-0 top-1/2 transform -translate-y-1/2"
                            >
                                <img src={WarningIcon} className={`p-4 h-12`} alt='warning' />
                            </div>
                            {errors.cvv && (
                                <div className="text-sm text-[#F42852]">
                                    {errors.cvv?.message}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={"w-full flex flex-col items-center"}>
                        <input
                            type="text"
                            placeholder="Enter Coupon"
                            className="input input-bordered rounded-full focus:outline-secondary w-full"
                            {...register("coupon")}
                        />
                        {errors.coupon && (
                            <div className="text-sm text-[#F42852]">
                                {errors.coupon?.message}
                            </div>
                        )}
                    </div>
                </div>
                <div className='flex justify-center w-1/2 mt-4'>
                    <button
                        type="submit"
                        className="btn hover:btn-secondary btn-outline rounded-full btn-wide"
                    >
                        Checkout
                    </button>
                </div>
                <div>
                    {errors.root && (
                        errors.root?.message
                    )}
                </div>
            </form>
            <FooterPhrase text={<p>By placing your order, your confirm you have read, understand and accepted the <span className='font-bold text-secondary'>Sweatsonic</span>™ <a href="www.google.com" className='link link-hover text-secondary underline'>Terms and Conditions</a> and <a href="www.google.com" className='link link-hover text-secondary underline'>Privacy Policy</a>, and <a href="www.google.com" className='link link-hover text-secondary underline'>Medical Disclaimer</a>.</p>} step={10} />
        </div>

    );
};

export default CheckoutPage;
