import WelcomeImage from 'assets/img/signup/welcome.png'
import FooterPhrase from 'components/FooterPhrase'
import { useReturnPath } from 'pages/auth/layout'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

const WelcomePage = () => {
    const { setPrevPath } = useReturnPath()
    useEffect(() => {
        setPrevPath("/signup/weight-selection");
    })
    return (
        <div className='h-full flex flex-col justify-between items-center w-full text-white font-semibold'>
            <div className='h-1/4 flex items-center justify-center'>
                <img src={WelcomeImage} className='w-1/2' alt='Welcome' />
            </div>
            <span className='text-white font-bold text-2xl'>Welcome</span>
            <div className='flex flex-col justify-center items-center font-normal text-sm w-2/3'>
                <p className='text-center'>Use This App At A <span className='text-secondary'>Gym Or At Home</span> if You Have Dumbbells.</p>
                <p className='text-center'>SweatSonic Replaces You Need For A <span className='text-secondary'>Personal Trainer</span>.</p>
                <p className='text-center'>Just Put On Your Headphones And <span className='text-secondary'>Follow The Music</span>.</p>
            </div>
            <Link to={"/signup/choose-plan"} className='btn btn-outline btn-wide rounded-full'>Continue</Link>
            <Link to="/" className="underline text-center text-sm font-normal text-gray-600">skip</Link>
            <FooterPhrase text={"Get Ready For Progress"} step={8} />
        </div>
    )
}

export default WelcomePage