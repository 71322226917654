import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { selectVideo, Workout, WorkoutVideo } from '../redux/workoutSlice'

const VideoCarousel = (props: { current: Workout }) => {
    const dispatch = useDispatch()
    const { current } = props
    const { loadingVideos } = useSelector((state: RootState) => state.workouts)

    const handleSelectVideo = (video: WorkoutVideo) => {
        dispatch(selectVideo(video))
    }

    return (
        <div>
            <div className='flex items-center justify-between px-6 text-white'>
                <p className='text-xl'>UP NEXT</p>
                <p className='text-xl'>{current.videos?.indexOf(current.currentVideo) + 1 + "/" + current?.videos?.length}</p>
            </div>
            <div className='carousel w-full h-28 space-x-2 p-4'>
                {loadingVideos ? <div className='h-full w-full pl-4 flex'><div className='skeleton bg-secondary/10 w-2/3 h-full'></div><div className='skeleton ml-4 bg-secondary/10 w-2/3 h-full'></div></div> :
                    current?.videos?.slice(current.videos?.indexOf(current.currentVideo) + 1, current.videos?.length).map((video, index) =>
                        <div key={index} className='carousel-item w-2/4 rounded-lg bg-black/75 border border-opacity-25'>
                            <div onClick={() => handleSelectVideo(video)} className='card flex-row relative p-4'>
                                <img className='rounded w-1/3 object-cover' src={video?.video_thumbnail_image} alt='workout' />
                                <div className='p-2 flex items-center'>
                                    <p className='text-xs'>{video.title}</p>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        </div>
    )
}

export default VideoCarousel