import { yupResolver } from '@hookform/resolvers/yup';
import FooterPhrase from 'components/FooterPhrase';
import { useReturnPath } from 'pages/auth/layout';
import React, { useEffect } from "react";
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";

interface IFormInput {
    goal: string | null;
}

const SetgoalPage = () => {
    const navigate = useNavigate();
    const { setPrevPath } = useReturnPath();
    useEffect(() => {
        setPrevPath(null);
    })

    const schema = Yup.object().shape({
        goal: Yup.string().nullable(),
    })

    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormInput>({
        defaultValues: {
            goal: localStorage.getItem('goal'),
        },
        resolver: yupResolver(schema) as any, // Type assertion to resolve the issue
    });

    const onSubmit = async (value) => {
        localStorage.setItem('goal', value.goal);
        navigate("/signup/set-preference");
    }

    return (
        <React.Fragment>
            <form className='flex flex-col justify-center items-center h-full w-full'>
                <p className='text-label text-2xl font-bold text-white text-center'>What's your goal?</p>
                {errors.goal && <p className='text-error text-center'>{errors.goal.message}</p>}
                <div className='flex flex-col justify-around space-y-2 w-10/12'>
                    <label className={`btn hover:btn-secondary rounded-full ${watch('goal') && watch('goal').includes('LOSE FAT') ? 'btn-secondary' : 'btn-outline'}`}>
                        <span className="">LOSE FAT</span>
                        <input type="radio" checked={watch('goal') && watch('goal').includes('LOSE FAT')} className="hidden" value={'LOSE FAT'} {...register('goal')} />
                    </label>
                    <label className={`btn hover:btn-secondary rounded-full ${watch('goal') && watch('goal').includes('BUILD MUSCLE') ? 'btn-secondary' : 'btn-outline'}`}>
                        <span className="">BUILD MUSCLE</span>
                        <input type="radio" checked={watch('goal') && watch('goal').includes('BUILD MUSCLE')} className="hidden" value={'BUILD MUSCLE'} {...register('goal')} />
                    </label>
                    <label className={`btn hover:btn-secondary rounded-full ${watch('goal') && watch('goal').includes('IMPROVE ENDURANCE') ? 'btn-secondary' : 'btn-outline'}`}>
                        <span className="">IMPROVE ENDURANCE</span>
                        <input type="radio" checked={watch('goal') && watch('goal').includes('IMPROVE ENDURANCE')} className="hidden" value={'IMPROVE ENDURANCE'} {...register('goal')} />
                    </label>
                    <label className={`btn hover:btn-secondary rounded-full ${watch('goal') && watch('goal').includes('MAINTAIN') ? 'btn-secondary' : 'btn-outline'}`}>
                        <span className="">MAINTAIN</span>
                        <input type="radio" checked={watch('goal') && watch('goal').includes('MAINTAIN')} className="hidden" value={'MAINTAIN'} {...register('goal')} />
                    </label>
                    <div className='flex justify-between'>
                        <button
                            onClick={() => navigate("/signup/set-profile")}
                            className="btn hover:btn-secondary btn-outline rounded-full px-8"
                        >
                            {"< Back"}
                        </button>
                        <button
                            type="submit"
                            className="btn hover:btn-secondary btn-outline rounded-full px-8"
                            onClick={handleSubmit(onSubmit)}
                        >
                            {"Next >"}
                        </button>
                    </div>
                </div>
                <Link to="/" className="underline text-center text-sm font-normal text-gray-600">skip</Link>
            </form>
            <FooterPhrase text='You can always adjust later.' step={2} />
        </React.Fragment>
    );
};

export default SetgoalPage;
