
const Tabs = (props: { active: string, setActive: any }) => {
    const { active, setActive } = props

    return (
        <div role="tablist" className="tabs tabs-bordered w-full justify-items-center">
            <button onClick={() => setActive('discover')} role="tab" className={`tab hover:tab-active ${active === 'discover' ? 'tab-active' : ''} w-10/12 lg:w-3/6`}>Discover</button>
            <button onClick={() => setActive('trainers')} role="tab" className={`tab hover:tab-active ${active === 'trainers' ? 'tab-active' : ''} w-10/12 lg:w-3/6`}>Trainers</button>
        </div>
    )
}

export default Tabs