import { yupResolver } from '@hookform/resolvers/yup';
import FooterPhrase from 'components/FooterPhrase';
import { useReturnPath } from 'pages/auth/layout';
import React, { useEffect } from "react";
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";

interface IFormInput {
    preference: string | null;
}

const SetPreferencePage = () => {
    const navigate = useNavigate();
    const { setPrevPath } = useReturnPath();
    useEffect(() => {
        setPrevPath(null);
    })

    const schema = Yup.object().shape({
        preference: Yup.string().nullable(),
    })

    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormInput>({
        defaultValues: {
            preference: localStorage.getItem('preference'),
        },
        resolver: yupResolver(schema) as any, // Type assertion to resolve the issue
    });

    const onSubmit = async (value) => {
        localStorage.setItem('preference', value.preference);
        navigate("/signup/set-activity");
    }

    return (
        <React.Fragment>
            <form className='flex flex-col justify-center items-center h-full w-full'>
                <p className='text-label text-2xl font-bold text-white text-center'>Workout Preference?</p>
                {errors.preference && <p className='text-error text-center'>{errors.preference.message}</p>}
                <div className='flex flex-col justify-around space-y-2 w-10/12'>
                    <label className={`btn hover:btn-secondary rounded-full ${watch('preference') && watch('preference').includes('Less Than 10 Minutes') ? 'btn-secondary' : 'btn-outline'}`}>
                        <span className="">Less Than 10 Minutes</span>
                        <input type="radio" checked={watch('preference') && watch('preference').includes('Less Than 10 Minutes')} className="hidden" value={'Less Than 10 Minutes'} {...register('preference')} />
                    </label>
                    <label className={`btn hover:btn-secondary rounded-full ${watch('preference') && watch('preference').includes('Less Than 20 Minutes') ? 'btn-secondary' : 'btn-outline'}`}>
                        <span className="">Less Than 20 Minutes</span>
                        <input type="radio" checked={watch('preference') && watch('preference').includes('Less Than 20 Minutes')} className="hidden" value={'Less Than 20 Minutes'} {...register('preference')} />
                    </label>
                    <label className={`btn hover:btn-secondary rounded-full ${watch('preference') && watch('preference').includes('Less Than 40 Minutes') ? 'btn-secondary' : 'btn-outline'}`}>
                        <span className="">Less Than 40 Minutes</span>
                        <input type="radio" checked={watch('preference') && watch('preference').includes('Less Than 40 Minutes')} className="hidden" value={'Less Than 40 Minutes'} {...register('preference')} />
                    </label>
                    <label className={`btn hover:btn-secondary rounded-full ${watch('preference') && watch('preference').includes('More Than 45 Minutes') ? 'btn-secondary' : 'btn-outline'}`}>
                        <span className="">More Than 45 Minutes</span>
                        <input type="radio" checked={watch('preference') && watch('preference').includes('More Than 45 Minutes')} className="hidden" value={'More Than 45 Minutes'} {...register('preference')} />
                    </label>
                    <div className='flex justify-between'>
                        <button
                            onClick={() => navigate("/signup/set-goal")}
                            className="btn hover:btn-secondary btn-outline rounded-full px-8"
                        >
                            {"< Back"}
                        </button>
                        <button
                            type="submit"
                            className="btn hover:btn-secondary btn-outline rounded-full px-8"
                            onClick={handleSubmit(onSubmit)}
                        >
                            {"Next >"}
                        </button>
                    </div>
                </div>
                <Link to="/" className="underline text-center text-sm font-normal text-gray-600">skip</Link>
            </form>
            <FooterPhrase text={"We use this information to build better routines for you"} step={3} />
        </React.Fragment>
    );
};

export default SetPreferencePage;
