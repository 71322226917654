import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authMiddleware from 'util/middleware';
import authReducer, { AuthState } from './authSlice';
import programReducer, { ProgramState } from './programSlice';
import trainerReducer, { TrainerState } from './trainerSlice';
import workoutReducer, { WorkoutState } from './workoutSlice';

export type RootState = {
  auth: AuthState,
  workouts: WorkoutState,
  programs: ProgramState,
  trainers: TrainerState
}

const rootReducer = combineReducers({
  auth: authReducer,
  workouts: workoutReducer,
  programs: programReducer,
  trainers: trainerReducer
})

const persistConfig = {
  key: 'auth',
  storage,
  whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(authMiddleware)
});

const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export { persistor, store };
