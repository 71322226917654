import axios from "axios";
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import config from "config/config";

const connection = axios.create({
  baseURL: config.defaults.api_url,
});

const setupAxiosInterceptors = (getAccessToken: () => string | null, getRefreshToken: () => string | null, onTokensRefreshed: (accessToken: string, refreshToken: string) => void) => {
  const refreshAuthLogic = async (failedRequest: any) => {
    const refreshToken = getRefreshToken();
    console.log("refreshToken: " + refreshToken)
    try {
      const response = await connection.post('/auth/refresh-token', { "refresh_token": refreshToken });
      const newAccessToken = response.data.accessToken;
      const newRefreshToken = response.data.refreshToken;
      onTokensRefreshed(newAccessToken, newRefreshToken);
      failedRequest.response.config.headers['Authorization'] = 'Bearer ' + newAccessToken;
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  createAuthRefreshInterceptor(connection, refreshAuthLogic);

  connection.interceptors.request.use(
    (config) => {
      const token = getAccessToken();
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};



export { connection, setupAxiosInterceptors };
