import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';
import { getCurrentUser } from '../redux/actions/authActions';
import { logout } from '../redux/authSlice';
import DesktopNav from './DesktopNav';

const Header = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { current } = useSelector((state: RootState) => state.auth);

    useEffect(() => {
        if (!current) {
            dispatch(getCurrentUser())
        }
    }, [current, dispatch])

    const handleLogout = () => {
        dispatch(logout());
    }

    return current ?
        <div className='flex justify-between lg:justify-end items-center px-4 w-full'>
            <DesktopNav />
            <div className='flex lg:flex-row-reverse items-center justify-center'>
                <div className="avatar">
                    <div className="w-14 rounded-full border border-white">
                        {current.profile_image ? <img src={current.profile_image} alt='profile' /> :
                            <div className='flex h-full justify-center items-center'>
                                <svg className="h-10 w-10" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12.2496 12C9.7826 12 7.7666 9.985 7.7666 7.517C7.7666 5.049 9.7826 3 12.2496 3C14.7166 3 16.7326 5.015 16.7326 7.483C16.7326 9.951 14.7166 12 12.2496 12Z" fill="#8E8E8E" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M19.25 21H5.25C4.7 21 4.25 20.55 4.25 20V19C4.25 16.8 6.05 15 8.25 15H16.25C18.45 15 20.25 16.8 20.25 19V20C20.25 20.55 19.8 21 19.25 21Z" fill="#8E8E8E" />
                                </svg>
                            </div>
                        }
                    </div>
                </div>
                <div className='flex flex-col w-full p-4'>
                    <div>
                        <p className='text-sm'>{new Date().toLocaleString('default', { weekday: 'short', month: 'short', day: 'numeric' })}</p>
                    </div>
                    <div>
                        <p className='text-white capitalize'>{current.user_name}</p>
                    </div>
                </div>
            </div>
            <div className='p-2'>
                <button onClick={handleLogout} className='text-3xl'>
                    <svg className='w-4' viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.0664062 1.37054L1.45949 0L8.06641 6.5L1.45949 13L0.0664062 11.6295L5.28023 6.5L0.0664062 1.37054Z" fill="white" fillOpacity="0.5" />
                    </svg>
                </button>
            </div>
        </div>
        :
        <div className='flex justify-evenly items-center py-4 px-6'>
            <div className='flex p-2'>
                <div className='h-14 w-14 skeleton rounded-full bg-secondary/25'></div>
                <div className='flex flex-col justify-center'>
                    <div className='skeleton h-4 w-40 bg-secondary/25'></div>
                    <div className='skeleton h-4 w-40 bg-secondary/25'></div>
                </div>
            </div>
            <button onClick={handleLogout} className='text-3xl'>
                <svg className='w-4' viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0.0664062 1.37054L1.45949 0L8.06641 6.5L1.45949 13L0.0664062 11.6295L5.28023 6.5L0.0664062 1.37054Z" fill="white" fillOpacity="0.5" />
                </svg>
            </button>
        </div>
}

export default Header